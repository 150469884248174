var generic = window.generic || {};

(function signUp($) {
  var contentBlockSignup = {
    setup: function (context) {
      var self = this; /* eslint no-shadow: ["error", { "allow": ["done"] }] */

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.init(context);
    },
    init: function (context) {
      var self = this;
      var $modules = $('.js-content-block-signup', context);
      var $emailForm = $();
      var $emailInput = $();
      var $emailSuccessMessage = $();
      var $emailErrorMessage = $();
      var $emailAlreadySignedUpMessage = $();
      var errorMessage = '';
      var successMessage;
      var alreadySignedupMessage = '';
      var params = {};
      var $thisModule;
      var isMobile;

      if ($modules.length === 0) {
        return;
      }

      $modules.each(function () {
        $thisModule = $(this);
        var $moduleData = $thisModule.data();

        $moduleWrapper = $thisModule.closest('.js-content-block-sitewide-banner__content-wrapper');
        $emailForm = $thisModule.find('.js-content-block-signup-form');
        $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailForm);
        $emailSuccessMessage = $thisModule.find('.js-content-block-signup-success');
        $emailErrorMessage = $thisModule.find('.js-content-block-signup-error');
        $emailAlreadySignedUpMessage = $thisModule.find('.js-content-block-signup-already-signed-up');
        $emailGdprErrorMessage = $thisModule.find('.js-content-block-signup-gdpr-error');
        $emailContent = $thisModule.find('.js-content-block-signup__content');
        $emailContentText = $thisModule.find('.js-content-block-signup__content-text');
        $emailContinueShoppingBtn = $thisModule.find('.js-content-block-signup__continue-shopping');
        $exitBtn = $thisModule.find('.js-content-block-signup__button-exit');
        $emailDisclaimer = $thisModule.find('.js-content-block-signup__disclaimer');
        $submitBtnWrapper = $thisModule.find('.js-content-block-signup__button');
        $submitBtn = $submitBtnWrapper.find('.js-content-block-signup__submit');
        $gdprWrapper = $thisModule.find('.js-content-block-signup__gdpr');
        $gdprInput = $thisModule.find('#content-block-signup--gdpr-check');
        $gdprToggle = $thisModule.find('.js-content-block-signup__gdpr-toggle');
        $gdprLabel = $thisModule.find('.js-content-block-signup__gdpr--label');
        errorMessage = $moduleData.errorText || '';
        grprErrorText = $moduleData.gdprErrorText || '';
        alreadySignedupMessage = $moduleData.signedUpText || '';

        Unison.on('change', function() {
          isMobile = self.checkIfMobile();
          if (isMobile) {
            self.setCollapsible($thisModule);
          } else {
            $emailContent.removeClass('hidden');
            $thisModule.removeClass('collapsible-signup-block');
            $moduleWrapper.removeClass('hide-trigger-btn');
          }
        })

        $emailInput.on('keyup', function emailInput() {
          var $currentElement = $(this);

          $currentElement.val().trim() === ''
            ? $currentElement.addClass('non-empty')
            : $currentElement.removeClass('non-empty');
        });

        $gdprInput.once().on('change', function() {
          $submitBtnWrapper.toggleClass('disabled');
          if ($gdprInput.is(':checked')) {
            $submitBtn.removeAttr('disabled');
            $emailGdprErrorMessage.addClass('hidden');
          }
        })

        if ($gdprToggle.length > 0) {
          $gdprToggle.once().on('click', function() {
            $gdprLabel.toggleClass('hidden');
          })
        }

        $submitBtnWrapper.once().on('click', function(e) {
          if ($submitBtnWrapper.hasClass('disabled')) {
            e.preventDefault();
           } else {
            $emailForm.trigger('submit');
           }
        });

        $emailForm.once($emailForm).on('submit', function errorMsg(event) {
          event.preventDefault();
          $emailForm.removeClass('content-block-signup--active-error');
          $emailSuccessMessage
            .add($emailErrorMessage)
            .add($emailAlreadySignedUpMessage)
            .add($emailGdprErrorMessage)
            .addClass('hidden');
          $emailInput.removeClass('error');

          // String into array of form elements
          params.PC_EMAIL_PROMOTIONS = 0;
          $emailForm.serializeArray().forEach(function (keyVal) {
            params[keyVal.name] = keyVal.value.replace('undefined', '').replace('%40', '@');
          });
          /* eslint no-underscore-dangle: ["error", { "allow": ["_TOKEN"] }] */
          if ($.cookie('csrftoken')) {
            params._TOKEN = $.cookie('csrftoken');
          }

          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var isSignedUp = response.userinfo.previously_opted_in_email_promotion;
              var updatedWrapperHeight = 0;
              var wrapperPadding = 32;

              $emailForm.addClass('content-block-signup--active-success');

              if (isSignedUp) {
                $emailAlreadySignedUpMessage.removeClass('hidden').html(alreadySignedupMessage);
                updatedWrapperHeight = $emailAlreadySignedUpMessage.height();
              } else {
                $emailSuccessMessage.removeClass('hidden').html(successMessage);
                updatedWrapperHeight = $emailSuccessMessage.children().height();
              }
              $emailContentText.addClass('hidden');
              $emailDisclaimer.addClass('hidden');
              $gdprWrapper.addClass('hidden');
              $submitBtnWrapper.addClass('hidden');
              $exitBtn.addClass('hidden');
              $moduleWrapper.removeClass('hide-trigger-btn');
              $thisModule.removeClass('expanded');
              $emailContinueShoppingBtn.length > 0 ? $emailContinueShoppingBtn.removeClass('hidden'): null;
              $emailInput.val('').removeClass('non-empty');
              isMobile ? $thisModule.css('min-height', updatedWrapperHeight + wrapperPadding) : null;
              $(document).trigger('contentBlock.signupSuccess');
            },
            onFailure: function (jsonRpcResponse) {
              var errObjs = jsonRpcResponse.getMessages();
              var errors = '';
              var iterate;
              var myErr;

              $emailErrorMessage.removeClass('hidden');
              var $errorObjectLength = errObjs.length;

              for (iterate = 0; iterate < $errorObjectLength; iterate++) {
                myErr = errObjs[iterate];
                if (myErr && myErr.key) {
                  if (
                    myErr.key === 'required.pc_email_address.email_signup' ||
                    myErr.key === 'invalid.pc_email_address.email_signup'
                  ) {
                    errors += myErr.text;
                    $emailInput.addClass('error').focus();
                  }
                }
              }
              if (errorMessage) {
                $emailForm.addClass('content-block-signup--active-error');
                $emailErrorMessage.html(errorMessage);
              } else if (errors) {
                $emailForm.addClass('content-block-signup--active-error');
                $emailErrorMessage.html(errors);
              }
            }
          });
        });
      });
    },

    disablePopup: function() {
      var isMobile = $('body').hasClass('device-mobile');
      var $bannerElement = $('#content_block_sitewide_banner_1');
      var referrerOrigin = document.referrer.toLowerCase();
      
      if (referrerOrigin.length > 0  && $bannerElement.length > 0) {
        if (isMobile && (referrerOrigin.includes('instagram') || referrerOrigin.includes('facebook'))) {
          $bannerElement.addClass('hidden');
        }
        if (referrerOrigin.includes('shop.maccosmetics.com.br')) {
          $bannerElement.addClass('hidden');
        }
      }
    },

    checkIfMobile: function() {
      var currentBpWidth = parseInt(Unison.fetch.now().width, 10);
      var bPlargeUp = parseInt(Unison.fetch.all()['usn-medium'], 10);
      return currentBpWidth <= bPlargeUp;
    },

    setCollapsible: function($thisModule) {
      $thisModule.addClass('collapsible-signup-block collapsed').removeClass('expanded');
      $moduleWrapper.addClass('hide-trigger-btn');
      var $emailContentHeader = $thisModule.find('.js-content-block-signup__header');
      var $emailContent = $thisModule.find('.js-content-block-signup__content');
      $emailContent.addClass('hidden');
      $emailContentHeader.once().on('click', function () {
        var $emailContentText = $thisModule.find('.js-content-block-signup__content-text');
        var open = $emailContentText.attr('aria-expanded') === 'false';

        $emailContentText.attr('aria-expanded', open.toString());
        $emailContentText
          .parent().next($emailContent)
          .attr('aria-hidden', !open.toString())
          .toggleClass('hidden', !open);
        $thisModule.removeClass('expanded collapsed');
        open ? $thisModule.addClass('expanded') && $moduleWrapper.removeClass('hide-trigger-btn') : $thisModule.addClass('collapsed') && $moduleWrapper.addClass('hide-trigger-btn');
      });
    }
  };

  Drupal.behaviors.contentBlockSignupV1 = {
    attached: false,
    attach: function (context) {
      var singUpBlockEnabled = Drupal.settings.globals_variables.disable_sign_up_block_by_referer;
      contentBlockSignup.setup(context);
      if (singUpBlockEnabled) {
        contentBlockSignup.disablePopup();
      }
    }
  };
})(jQuery);
